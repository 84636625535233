<template>
  <div class="trips-hero-images">
    <div class="trips-hero-images__wrapper">
      <div class="content-view__image-holder">
        <TripHeroImage :key="item.id"
                       :params="item"
                       v-for="item in items"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TripHeroImages',
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
